<template>
  <v-container v-bind:style="{ 'max-width': '100%' }">
    <v-row>
      <v-col cols="12" md="8">
        <v-data-table
          :headers="headers"
          :items="listaMaterias"
          :search="search"
          no-results-text="Sin resultados"
          sort-by="Nombre"
          class="elevation-1"
          :header-props="{ sortByText: 'Organizar por' }"
          :items-per-page="listaMaterias.length"
          :footer-props="{
            'items-per-page-text': 'items por página',
            'items-per-page-all-text': 'Todos',
            'items-per-page-options': [-1, 5, 10, 15],
          }"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                >
                </v-text-field>
              </v-card-title>
              <v-dialog v-model="dialog" max-width="700px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    small
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Nueva materia
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">Nueva materia / editar </span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.codigo"
                            label="Código"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.nombre"
                            label="Nombre"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.nombreCompleto"
                            label="Nombre completo"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            :items="[4, 5, 6, 7, 8, 9]"
                            v-model="editedItem.cantidadDeNotas"
                            label="No. de notas"
                            @change="reiniciar"
                            placeholder="No. de notas"
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="4"
                          md="4"
                          v-for="(data, index) in editedItem.cantidadDeNotas"
                          :key="index"
                        >
                          <v-text-field
                            v-model="editedItem.porcentajeNotasPred[index]"
                            label="Puntos porcentuales: "
                            @input="sumaP"
                            single-line
                            hide-details
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <br />
                      <h4 color="accent">{{ "* " + msjP }}</h4>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="accent" text @click="close"> Cancelar </v-btn>
                    <v-btn
                      :disabled="buscando"
                      color="primary "
                      text
                      @click="save"
                    >
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-if="dialogDelete"
                v-model="dialogDelete"
                max-width="500px"
              >
                <v-card>
                  <v-card-title v-bind:style="{ 'text-align': 'center' }"
                    >Deseas borrar la materia {{ editedItem.nombre }}?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="accent" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn
                      :disabled="buscando"
                      color="primary"
                      text
                      @click="deleteItemConfirm"
                      >SI</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="editItem(item)">
              <v-icon small> mdi-pencil </v-icon>
            </v-btn>
            <v-btn v-if="adminLevel == -1" icon @click="deleteItem(item)">
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-row>
              <v-col cols="12" md="12">
                <v-container>
                  <v-progress-circular
                    v-if="listaMaterias.length == 0"
                    :width="3"
                    :size="20"
                    color="primary"
                    indeterminate
                  >
                  </v-progress-circular>
                </v-container>
              </v-col>
              <v-col cols="12" md="12">
                <v-btn
                  small
                  v-bind:style="{
                    'margin-top': '10px',
                    'margin-bottom': '10px',
                  }"
                  color="primary"
                  @click="initialize"
                >
                  Volver a cargar
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" md="4">
        <v-data-table
          :headers="headersP"
          :items="listaPeriodos"
          :search="searchp"
          no-results-text="Sin resultados"
          sort-by="Nombre"
          class="elevation-1"
          :header-props="{ sortByText: 'Organizar por' }"
          :items-per-page="listaPeriodos.length"
          :footer-props="{
            'items-per-page-text': 'items por página',
            'items-per-page-all-text': 'Todos',
            'items-per-page-options': [-1, 5, 10, 15],
          }"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-card-title>
                <v-text-field
                  v-model="searchp"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                >
                </v-text-field>
              </v-card-title>
              <v-dialog v-model="dialogP" max-width="700px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    small
                  >
                    Nuevo periodo
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline"> Agregar periodo</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItemP.idP"
                            label="Id periodo"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-menu
                            ref="menuI"
                            v-model="menuI"
                            :close-on-content-click="false"
                            :return-value.sync="editedItemP.fechaInicio"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedItemP.fechaInicio"
                                label="Fecha inicial"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              locale="ES"
                              v-model="date"
                              no-title
                              scrollable
                              @change="$refs.menuI.save(date)"
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-menu
                            ref="menuF"
                            v-model="menuF"
                            :close-on-content-click="false"
                            :return-value.sync="editedItemP.fechaFin"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedItemP.fechaFin"
                                label="Fecha final"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              locale="ES"
                              v-model="date"
                              no-title
                              scrollable
                              @change="$refs.menuF.save(date)"
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="accent" text @click="closeP">
                      Cancelar
                    </v-btn>
                    <v-btn
                      :disabled="buscando"
                      color="primary"
                      text
                      @click="saveP"
                    >
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-if="dialogDeleteP"
                v-model="dialogDeleteP"
                max-width="500px"
              >
                <v-card>
                  <v-card-title v-bind:style="{ 'text-align': 'center' }"
                    >Deseas borrar el periodo {{ editedItemP.idP }}?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="accent" text @click="closeDeleteP"
                      >Cancelar</v-btn
                    >
                    <v-btn
                      :disabled="buscando"
                      color="primary"
                      text
                      @click="deleteItemConfirmP"
                      >SI</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:[`item.fechaInicio`]="{ item }">
            <span>{{ $moment(item.fechaInicio).format("YYYY/MM/DD") }}</span>
          </template>

          <template v-slot:[`item.fechaFin`]="{ item }">
            <span>{{ $moment(item.fechaFin).format("YYYY/MM/DD") }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn v-if="adminLevel == -1" icon @click="deleteItemP(item)">
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
            <v-btn
              v-if="adminLevel == -1"
              v-show="today > item.fechaFin"
              icon
              @click="dialogCleanP(item)"
            >
              <v-icon small> mdi-eraser-variant </v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <v-row>
              <v-col cols="12" md="12">
                <v-container>
                  <v-progress-circular
                    v-if="listaPeriodos.length == 0"
                    :width="3"
                    :size="20"
                    color="primary"
                    indeterminate
                  >
                  </v-progress-circular>
                </v-container>
              </v-col>
              <v-col cols="12" md="12">
                <v-btn
                  small
                  v-bind:style="{
                    'margin-top': '10px',
                    'margin-bottom': '10px',
                  }"
                  color="primary"
                  @click="initialize"
                >
                  Volver a cargar
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import firebase from "firebase";
import { host } from "./Tools/variables";
import axios from "axios";
import data from "./Tools/data";

export default {
  data() {
    return {
      today: new Date().toISOString(),
      search: "",
      searchp: "",
      date: new Date().toISOString().substr(0, 10),
      dialog: false,
      dialogP: false,
      dialogDelete: false,
      dialogDeleteP: false,
      dialogEdit: false,
      dialogEditP: false,
      nombreM: null,
      nombreCompletoM: null,
      headers: [
        {
          text: "Código",
          align: "start",
          sortable: false,
          value: "codigo",
        },
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "nombre",
        },
        { text: "Nombre completo", value: "nombreCompleto" },
        { text: "No. de notas", value: "cantidadDeNotas" },
        { text: "% notas", value: "porcentajeNotasPred" },
        { text: "", value: "actions" },
      ],
      headersP: [
        { text: "ID", value: "idP", sortable: true },
        { text: "Fecha inicial", value: "fechaInicio", sortable: true },
        { text: "Fecha final", value: "fechaFin", sortable: true },
        { text: "", value: "actions" },
      ],
      listaPeriodos: [],
      listaMaterias: [],
      editedIndex: -1,
      editedItem: {
        nombre: "",
        nombreCompleto: "",
        cantidadDeNotas: "",
        porcentajeNotasPred: [],
        codigo: [],
      },
      defaultItem: {
        nombre: "",
        nombreCompleto: "",
        cantidadDeNotas: "",
        nuevoNombre: "",
        codigo: [],
      },
      editedIndexP: -1,
      editedItemP: {
        nombre: "",
        idP: "",
        fechaInicio: "",
        fechaFin: "",
        nuevoIdP: "",
      },
      defaultItemP: {
        nombre: "",
        idP: "",
        fechaInicio: "",
        fechaFin: "",
        nuevoIdP: "",
      },
      menuI: false,
      menuF: false,
      idM: null,
      adminLevel: null,
      active: false,
      buscando: false,
      msjP: "Sin datos",
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogP(val) {
      val || this.closeP();
    },
    dialogDeleteP(val) {
      val || this.closeDeleteP();
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Agregar periodo" : "Editar periodo";
    },
  },

  async created() {
    if (this.$store.state.periodos.length == 0) {
      this.listaPeriodos = await data.dataList(
        "/periodos",
        "lista",
        "listar",
        "periodos"
      );
    } else {
      this.listaPeriodos = this.$store.state.periodos;
    }
    if (this.$store.state.cursos.length == 0) {
      this.listaMaterias = await data.dataList(
        "/cursosBase",
        "lista",
        "listar",
        "cursos"
      );
    } else {
      this.listaMaterias = this.$store.state.cursos;
    }
    this.adminLevel = this.$store.state.adminLevel;
  },

  mounted() {
    document.title = "Periodos";
  },

  methods: {
    async initialize() {
      this.listaPeriodos = await data.dataList(
        "/periodos",
        "lista",
        "listar",
        "periodos"
      );
      this.listaMaterias = await data.dataList(
        "/cursosBase",
        "lista",
        "listar",
        "cursos"
      );
    },

    editItem(item) {
      this.editedIndex = this.listaMaterias.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.dialogEdit = true;
      this.idM = item._id;
    },

    reiniciar() {
      this.editedItem.porcentajeNotasPred = [];
    },

    deleteItem(item) {
      this.buscando = false;
      this.editedIndex = this.listaMaterias.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
      this.idM = item._id;
    },

    deleteItemConfirm() {
      this.buscando = true;
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "editar");
          fd.append("nombre", this.editedItem.nombre);
          fd.append("idC", this.idM);
          fd.append("borrar", "SI");
          axios.post(host + "/cursosBase", fd).then((result) => {
            this.buscando = false;
            let data = result.data.text;
            if (data == "OK") {
              this.listaMaterias.splice(this.editedIndex, 1);
              this.closeDelete();
            } else {
              this.$alert(
                "Error " +
                  "No se guardo la informacion del curso correctamente, intente nuevamente"
              );
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    close() {
      this.buscando = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.buscando = true;
      if (this.msjP == "OK, ptos totales: 500") {
        if (this.dialogEdit == true) {
          firebase
            .auth()
            .currentUser.getIdToken(/* forceRefresh */ true)
            .then((idToken) => {
              const fd = new FormData();
              fd.append("token", idToken);
              fd.append("tipo", "editar");
              fd.append("idC", this.idM);
              fd.append("nombre", this.editedItem.nombre);
              fd.append("nombreCompleto", this.editedItem.nombreCompleto);
              fd.append("codigo", this.editedItem.codigo);
              fd.append("cantidadDeNotas", this.editedItem.cantidadDeNotas);
              fd.append(
                "porcentajeNotasPred",
                this.editedItem.porcentajeNotasPred
              );
              axios.post(host + "/cursosBase", fd).then((result) => {
                this.buscando = false;
                let data = result.data.text;
                this.dialogEdit = false;
                if (data == "OK") {
                  this.dialog = null;
                  this.idM = null;
                } else {
                  if (
                    data ==
                    "Error, no se puede modificar una materia que ya tiene estudiantes"
                  ) {
                    this.$alert(data);
                  } else {
                    this.$alert(
                      "Error " +
                        "No se guardo la informacion del curso correctamente, intente nuevamente"
                    );
                  }
                }
              });
            })
            .catch(function (error) {
              this.$alert("Error " + error.message);
            });
        } else {
          firebase
            .auth()
            .currentUser.getIdToken(/* forceRefresh */ true)
            .then((idToken) => {
              const fd = new FormData();
              fd.append("token", idToken);
              fd.append("tipo", "agregar");
              fd.append("nombre", this.editedItem.nombre);
              fd.append("nombreCompleto", this.editedItem.nombreCompleto);
              fd.append("cantidadDeNotas", this.editedItem.cantidadDeNotas);
              fd.append(
                "porcentajeNotasPred",
                this.editedItem.porcentajeNotasPred
              );
              fd.append("codigo", this.editedItem.codigo);
              axios.post(host + "/cursosBase", fd).then((result) => {
                let data = result.data.text;
                this.buscando = false;
                this.initialize();
                if (data == "OK") {
                  if (this.editedIndex > -1) {
                    Object.assign(
                      this.listaMaterias[this.editedIndex],
                      this.editedItem
                    );
                  } else {
                    this.listaMaterias.push(this.editedItem);
                  }
                  this.close();
                } else {
                  this.$alert(
                    "Error " +
                      "No se guardo la informacion del curso correctamente, intente nuevamente"
                  );
                }
              });
            })
            .catch(function (error) {
              this.$alert("Error " + error.message);
            });
        }
      } else {
        this.$alert("La suma de los ptos porcentuales es incorrecta");
      }
    },
    // Periodos
    editItemP(item) {
      this.editedIndexP = this.listaPeriodos.indexOf(item);
      this.editedItemP = Object.assign({}, item);
      this.dialogP = true;
      this.nombreCompletoP = item.nombreCompleto;
      this.nombreP = item.nombre;
      this.dialogEditP = true;
    },

    deleteItemP(item) {
      this.buscando = false;
      this.editedIndexP = this.listaPeriodos.indexOf(item);
      this.editedItemP = Object.assign({}, item);
      this.dialogDeleteP = true;
    },

    deleteItemConfirmP() {
      this.buscando = true;
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          const fd = new FormData();
          fd.append("token", idToken);
          fd.append("tipo", "eliminar");
          fd.append("idP", this.editedItemP.idP);
          axios.post(host + "/periodos", fd).then((result) => {
            let data = result.data.text;
            this.buscando = false;
            if (data == "OK") {
              this.listaPeriodos.splice(this.editedIndexP, 1);
              this.closeDeleteP();
            } else {
              this.$alert(data);
            }
          });
        })
        .catch(function (error) {
          this.$alert("Error " + error.message);
        });
    },

    dialogCleanP(item) {
      this.editedIndexP = this.listaPeriodos.indexOf(item);
      this.$confirm({
        auth: true,
        message: `Escribe "borrar-" seguido del periodo que deseas eliminar (sin espacios) Ej: borrar-123`,
        button: {
          no: "Cancelar",
          yes: "Aceptar",
        },
        callback: (confirm, password) => {
          if (
            confirm == true &&
            password.toLowerCase() == "borrar-" + item.idP
          ) {
            firebase
              .auth()
              .currentUser.getIdToken(/* forceRefresh */ true)
              .then((idToken) => {
                const fd = new FormData();
                fd.append("token", idToken);
                fd.append("tipo", "limpiar");
                fd.append("idP", item.idP);
                axios.post(host + "/periodos", fd).then((result) => {
                  this.buscando = false;
                  let data = result.data.text;
                  if (data == "OK") {
                    this.initialize();
                    this.listaPeriodos.splice(this.editedIndexP, 1);
                    this.$alert(
                      "Se eliminó toda la información del periodo correctamente"
                    );
                  } else {
                    this.$alert(
                      "Error:" +
                        " No se eliminó  el periodo correctamente, intente nuevamente"
                    );
                  }
                });
              })
              .catch(function (error) {
                this.$alert("Error " + error.message);
              });
          } else {
            this.$alert("Error: confirmación denegada");
          }
        },
      });
    },

    closeP() {
      this.dialogP = false;
      this.$nextTick(() => {
        this.editedItemP = Object.assign({}, this.defaultItemP);
        this.editedIndexP = -1;
      });
    },

    closeDeleteP() {
      this.dialogDeleteP = false;
      this.$nextTick(() => {
        this.editedItemP = Object.assign({}, this.defaultItemP);
        this.editedIndexP = -1;
      });
    },

    saveP() {
      this.buscando = true;
      if (this.dialogEditP == true) {
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            const fd = new FormData();
            fd.append("token", idToken);
            fd.append("tipo", "editar");
            fd.append("idP", this.editedItemP.idP);
            fd.append("nombre", this.editedItemP.nombre);
            fd.append("fechaInicio", this.editedItemP.fechaInicio);
            fd.append("fechaFin", this.editedItemP.fechaFin);
            fd.append("nuevoIdP", this.editedItemP.nuevoIdP);
            axios.post(host + "/periodos", fd).then((result) => {
              this.buscando = false;
              let data = result.data.text;
              if (data == "OK") {
                this.initialize();
                this.dialogEditP = false;
              } else {
                this.$alert(
                  "Error " +
                    "No se guardó la informacion del periodo correctamente, intente nuevamente"
                );
              }
            });
          })
          .catch(function (error) {
            this.$alert("Error " + error.message);
          });
      } else {
        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((idToken) => {
            const fd = new FormData();
            fd.append("token", idToken);
            fd.append("tipo", "agregar");
            fd.append("idP", this.editedItemP.idP);
            // fd.append('nombre', this.editedItemP.nombre);
            fd.append("fechaFin", this.editedItemP.fechaFin);
            fd.append("fechaInicio", this.editedItemP.fechaInicio);
            axios.post(host + "/periodos", fd).then((result) => {
              let data = result.data.text;
              this.buscando = false;
              if (data == "OK") {
                this.dialogP = false;
                if (this.editedIndexP > -1) {
                  Object.assign(
                    this.listaPeriodos[this.editedIndexP],
                    this.editedItemP
                  );
                } else {
                  this.listaPeriodos.push(this.editedItemP);
                }
                this.closeP();
              } else {
                this.$alert(
                  "Error " +
                    "No se guardó la informacion del periodo correctamente, intente nuevamente"
                );
              }
            });
          })
          .catch(function (error) {
            this.$alert("Error " + error.message);
          });
      }
    },

    sumaP() {
      var data = Object.values(this.editedItem.porcentajeNotasPred);
      var suma = 0;
      for (var i = 0; i < data.length; i++) {
        suma = suma + parseInt(data[i]);
      }
      if (suma == 500) {
        this.msjP = "OK, ptos totales: 500";
      }
      if (suma > 500) {
        this.msjP = "Error, ptos totales mayor a 500";
      }
      if (suma < 500) {
        this.msjP = "Error, ptos totales menor a 500";
      }
      if (suma == 0) {
        this.msjP = "Sin datos";
      }
    },
  },
};
</script>